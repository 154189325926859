<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <div class="top">
        <el-form-item label="支付状态：" class="form-item">
          <el-select v-model="form.orderState" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="未支付" value="0"></el-option>
            <el-option label="已付款" value="1"></el-option>
            <el-option label="部分退款" value="3"></el-option>
            <el-option label="已退款" value="2"></el-option>
            <el-option label="部分支付" value="6"></el-option>
            <el-option label="已取消" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型：" class="form-item">
          <el-select v-model="form.orderType" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="收款订单" value="1"></el-option>
            <el-option label="加购订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单ID：" class="form-item">
          <el-input
            v-model="form.outTradeNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>

        <el-form-item label="支付流水号：" class="form-item">
          <el-input
            v-model="form.paymentOrderNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>

        <el-form-item label="所属大区：" class="form-item">
          <KeySelect :val.sync="form.cityId" class="select" city />
        </el-form-item>

        <el-form-item class="form-item" label-width="30px">
          <el-link type="primary" style="margin-right: 10px" @click="checkFold"
            >{{ fold ? '收起' : '展开' }}
          </el-link>
          <el-button icon="el-icon-search" type="primary" size="small" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-left" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </div>

      <div v-show="fold" class="more">
        <!-- <el-form-item label="所属客户：" class="form-item">
          <el-input
            v-model="form.customerName"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="支付方式：" class="form-item">
          <el-select v-model="form.channelPayWayCode" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="微信" value="WECHAT"></el-option>
            <el-option label="支付宝" value="ALIPAY"></el-option>
            <!-- <el-option label="银行卡" value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="支付时间：">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px"> </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PageHeader',
  data() {
    return {
      form: { orderState: '', paymentOrderNo: '' },
      fold: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.form.paymentOrderNo = this.$route.query.paymentOrderNo || '';
      if (this.form.paymentOrderNo) this.onSubmit();
    }, 150);
  },
  methods: {
    onSubmit() {
      const data = { ...this.form };
      if (data.time) {
        data.beginPaymentTime = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss');
        data.endPaymentTime = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      delete data.time;
      this.$emit('search', data);
    },
    reset() {
      Object.keys(this.form).forEach((el) => (this.form[el] = ''));
      this.$emit('search', this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    },
    filterStatusList(status) {
      this.form.orderState = String(status < 0 ? '' : status);
      this.onSubmit();
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
