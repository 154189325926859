<template>
  <section class="records-wrapper">
    <div>
      <search ref="searchComponent" @search="search" />

      <div v-if="totalStatisticsArr.length > 0" class="total-list">
        <div v-for="item in totalStatisticsArr" :key="item.orderStateName" class="total-item" @click="filterStatusList(item.orderState)">
          <div>
            {{ item.orderStateName || '' }} <span>({{ item.payOrderNum || 0 }})</span>
          </div>

          <el-popover placement="top-start" title="" width="100" trigger="hover" content="已退/全部" :disabled="!item.orderStateName.includes('部分退款')">
            <div slot="reference" class="amount">¥{{ item.amount || 0 }}</div>
          </el-popover>
        </div>
      </div>

      <div class="table-wrapper">
        <vxe-grid ref="xTable" v-loading="loading" border :data="tableData" :columns="tableColumn" size="small" v-bind="tableOptions">
          <template #toolbar_buttons>
            <el-button icon="el-icon-plus" size="small" type="default" @click="handleExport">导出 </el-button>
          </template>
          <template #paymentAmount="{ row }">
            <span v-if="row.paymentAmount">¥{{ row.paymentAmount }}</span>
            <span v-else>--</span>
          </template>
          <template #refundableAmount="{ row }">
            <span v-if="row.refundableAmount">¥{{ row.refundableAmount }}</span>
            <span v-else>--</span>
          </template>
          <template #refundAmount="{ row }">
            <span v-if="row.refundAmount">¥{{ row.refundAmount }}</span>
            <span v-else>--</span>
          </template>
          <template #debtAmount="{ row }">
            <span v-if="row.debtAmount">¥{{ row.debtAmount }}</span>
            <span v-else>--</span>
          </template>
          <template #netPaymentAmount="{ row }">
            <span v-if="row.netPaymentAmount">¥{{ row.netPaymentAmount }}</span>
            <span v-else>--</span>
          </template>
          <template #orderType="{ row }">
            <span>
              {{ row.orderType === 1 ? '收款订单' : '加购订单' }}
            </span>
          </template>
          <template #orderStateName="{ row }"
            ><span :style="{ color: row.orderState == 1 ? '#70b603' : '#d9001b' }">
              {{ row.orderState == 1 ? '已付款' : row.orderStateName }}
            </span>
          </template>
          <template #outTradeNo="{ row }">
            <el-link :underline="false" type="primary" @click="goOrderDetail(row)">{{ row.outTradeNo }}</el-link>
          </template>
          <template #customerName="{ row }">
            <el-link :underline="false" type="primary" @click="goCustDetail(row)">{{ row.customerName }}</el-link>
          </template>

          <template #orderRegistNum="{ row }">
            <el-button type="text" @click="toOrderAudit(row)">{{ row.orderRegistNum }}</el-button>
          </template>

          <template #receivableAmount="{ row }">
            <span>¥{{ row.receivableAmount || 0 }}</span>
          </template>
<!--  v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_PAYDETAIL')" -->
          <template #operate="{ row }">
            <el-button v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_PAYDETAIL')" type="text" @click="detail(row)">详情</el-button>
          </template>
          <template #pager>
            <i-pagination
              :page-size="iPagination.pageSize"
              :current-page="iPagination.currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :total="total"
              @current-change="handlePageChange"
              @size-change="handleSizeChange"
            />
          </template>
        </vxe-grid>
      </div>
    </div>
  </section>
</template>

<script>
import Search from './components/search/index.vue'
import IPagination from '@/components/i-pagination/index.vue'
import { paymentorderList, getOrders, paymentorderExport } from '@/api/pay'
import { downloadFile } from '@/utils/fileDownload'
import { paymentorderFindTotalStatistics } from '@/api/receiveAudit'

export default {
  name: 'PayOrderRecord',
  components: {
    Search,
    // eslint-disable-next-line vue/no-unused-components
    IPagination
  },
  data() {
    return {
      iPagination: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      tableColumn: [
        // { type: 'checkbox', minWidth: 50, fixed: 'left' },
        {
          field: 'paymentOrderNo',
          title: '支付流水号',
          minWidth: 220,
          fixed: 'left',
        },
        {
          field: 'orderRegistNum',
          title: '关联收款凭证',
          minWidth: 120,
          slots: { default: 'orderRegistNum' },
        },
        { field: 'outTradeNo', title: '订单ID', minWidth: 220, slots: { default: 'outTradeNo' } },
        { field: 'orderTypeName', title: '订单类型', minWidth: 120 },
        {
          field: 'customerName',
          title: '所属客户',
          minWidth: 120,
          slots: { default: 'customerName' },
        },
        {
          field: 'cityName',
          title: '所属大区',
          minWidth: 120,
        },
        {
          field: 'receivableAmount',
          slots: { default: 'receivableAmount' },
          title: '订单应付',
          minWidth: 120
        },
        {
          field: 'paymentAmount',
          slots: { default: 'paymentAmount' },
          title: '已付金额',
          minWidth: 120
        },
        {
          field: 'debtAmount',
          slots: { default: 'debtAmount' },
          title: '欠款金额',
          minWidth: 120
        },
        { field: 'channelPayModeName', title: '支付方式', minWidth: 120 },
        { field: 'paymentTime', title: '支付时间', minWidth: 180 },
        {
          field: 'orderStateName',
          title: '支付状态',
          slots: { default: 'orderStateName' },
          minWidth: 100,
        },
        {
          field: 'refundableAmount',
          slots: { default: 'refundableAmount' },
          title: '应退金额',
          minWidth: 120
        },
        {
          field: 'refundAmount',
          slots: { default: 'refundAmount' },
          title: '已退金额',
          minWidth: 120,
        },
        {
          field: 'netPaymentAmount',
          slots: { default: 'netPaymentAmount' },
          title: '净收金额',
          minWidth: 120
        },
        { title: '操作', slots: { default: 'operate' }, minWidth: 80, fixed: 'right' },
      ],
      tableData: [],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons',
          },
        },
      },

      total: 0,
      totalStatisticsArr: [],
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList ;
    }
  },
  created() {
    this.getTableData()
    this.getOrderStatistics({})
  },
  methods: {
    getStateName(val) {
      switch (val) {
        case 0:
          return '未支付'
        case 1:
          return '已支付成功'
        case 2:
          return '已退款'
        case 3:
          return '部分退款'
        case 4:
          return '已取消'
        case 5:
          return '已失败'

        default:
          return '--'
      }
    },
    goOrderDetail(row) {
      let url = ''
      if (Number(row.orderType) === 1) {
        url = '/index/service/receivingOrder/receivingParticulars?id='
      } else {
        url = '/index/service/purchaseOrder/purchaseParticulars?id='
      }
      getOrders({
        data: { orderNo: row.outTradeNo },
      }).then((res) => {
        history.pushState(null, null, url + res[0].orderId)
      })
    },
    goCustDetail(row) {
      history.pushState(null, null, `/index/chance/clientFollowUp/particulars?id=${row.customerId}`)
    },
    search(data) {
      this.iPagination.pageNumber = 1
      this.iPagination.data = { ...data }
      this.getTableData()
      this.getOrderStatistics({ ...data })
    },
    detail(row) {
      this.$router.push({
        path: '/record/detail',
        query: { id: row.paymentOrderId, orderNo: row.outTradeNo, type: row.orderType },
      })
    },
    getTableData() {
      this.loading = true
      paymentorderList(this.iPagination).then((res) => {
        this.loading = false
        this.tableData = res.content
        this.total = Number(res.total)
      })
    },

    // 页码发生变化
    handlePageChange(current) {
      this.iPagination.pageNumber = current
      this.getTableData()
    },
    // 每页显示的条数发生的变化
    handleSizeChange(pageSize) {
      this.iPagination.pageSize = pageSize
      this.getTableData()
    },
    handleExport() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.iPagination))
      data.data.isExport = 1
      paymentorderExport(data)
        .then((res) => {
          downloadFile(res, '支付记录', '.xlsx')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    toOrderAudit(row) {
      if (row.orderRegistNum <= 0) return
      this.$router.push({ name: 'ReceiveAuditList', query: { orderNo: row.outTradeNo } })
    },
    async getOrderStatistics(data = {}) {
      const res = await paymentorderFindTotalStatistics({ data })
      this.totalStatisticsArr = res || []
    },
    filterStatusList(status) {
      if (status < 0) return
      this.$refs.searchComponent.filterStatusList(status)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>

<style lang="scss">
.el-popover--plain {
  padding: 10px !important;
}
</style>
